<template>
    <vx-card title="General">
        <p>Aquí se puede modificar la información más relevante de la página.</p>

        <div v-if="initProgress" style="margin-top: 1rem; width: 100%">
            <vs-progress indeterminate color="primary"></vs-progress>
        </div>

        <div class="mt-5">
            <!--Title-->
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input @blur="content.title= trimString(content.title)"
                              v-validate="'required|min:20|max:100'"
                              name="title"
                              class="w-full" label="Titulo"
                              v-model="content.title"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('title')">{{ errors.first('title') }}</span>
                </div>
            </div>
            <!--End title-->
            <!--Description-->
            <div class="vx-row">
                <div class="vx-col w-full">
                    <vs-textarea @blur="content.description= trimString(content.description)"
                                 v-validate="'required|min:10|max:350'"
                                 name="descripction" label="Descripción"
                                 v-model="content.description"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('descripction')">{{ errors.first('descripction') }}</span>
                </div>
            </div>
            <!--End description-->
            <!--Socials-->
            <div class="vx-row mb-6">
                <div class="vx-col w-1/3">
                    <vs-input @blur="content.facebook= trimString(content.facebook)"
                              v-validate="'required|min: 5|max:50'"
                              name="facebook" class="w-full" label="Facebook"
                              v-model="content.facebook"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('facebook')">{{ errors.first('facebook') }}</span>
                </div>
                <div class="vx-col w-1/3">
                    <vs-input @blur="content.linkedin= trimString(content.linkedin)"
                              v-validate="'required|min: 5|max:50'"
                              name="linkedin" class="w-full" label="Linkedin"
                              v-model="content.linkedin"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('linkedin')">{{ errors.first('linkedin') }}</span>
                </div>
            </div>
            <!--End-->
            <!--Phone and email-->
            <div class="vx-row mb-6">
                <div class="vx-col w-1/2">
                    <vs-input @blur="content.phone= trimString(content.phone)" v-validate="'required'"
                              name="phone" class="w-full" label="Telefono"
                              v-model="content.phone"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
                </div>
                <div class="vx-col w-1/2">
                    <vs-input @blur="content.email= trimString(content.email)" v-validate="'required'"
                              name="email" class="w-full" label="Email"
                              v-model="content.email"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('email')">{{ errors.first('email') }}</span>
                </div>
            </div>
            <!---->
            <div class="flex mt-6 flex-wrap items-center" slot="footer">
                <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Actualizar</vs-button>
                <div v-if="progress" style="margin-top: 1rem; width: 100%">
                    <vs-progress indeterminate color="primary"></vs-progress>
                </div>
            </div>

        </div>
    </vx-card>
</template>

<script>
  import trimString from "../mixins/trimString";

  const firebase = require("firebase")
  // Required for side-effects
  require("firebase/firestore")
  let db = firebase.firestore()

  export default {
    name: "Content",
    mixins: [
      trimString
    ],
    data() {
      return {
        content: {},
        progress: false,
        initProgress: false
      }
    },
    created() {
      this.initProgress = true
      db.collection("content").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.content = {
            id: doc.id,
            ...doc.data()
          }
        })
        this.initProgress = false
      })
    },
    computed: {
      isFormValid() {
        return !this.errors.any()
      }
    },
    methods: {
      async submitData() {
        try {
          const result = await this.$validator.validateAll()
          if (result) {
            this.progress = true
            // Content
            let contentRef = db.collection('content').doc(this.content.id)
            //Clone to remove the id
            let cloneContent = Object.assign({}, this.content)
            delete cloneContent.id
            await contentRef.update({
              ...cloneContent,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp()
            })
            this.$vs.notify({
              color: 'success',
              title: 'Contenido',
              text: 'Contenido modificado correctamente.'
            })
          }
        } catch (e) {
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        } finally {
          this.progress = false
        }
      }
    }
  }
</script>

<style scoped>

</style>
